import React from 'react';

import CalculatorCore, {Props as CalculatorCoreProps} from './CalculatorCore';
import VCEExtensionStudy from './VCEExtensionStudy';
import {ExtraData} from '../types';

export type Props = CalculatorCoreProps & {
  extraData: ExtraData;
  onChangeExtraData: (key: string, value: string) => void;
};

export default class CalculatorVCE extends React.Component<Props> {
  render() {
    return (
      <CalculatorCore
        {...this.props}
        extraHeader={
          <VCEExtensionStudy
            value={+this.props.extraData.increment}
            onChange={this._onChangeIncrement}
          />
        }
      />
    );
  }

  _onChangeIncrement = (value: number) =>
    this.props.onChangeExtraData('increment', '' + value);
}
