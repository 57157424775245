import React, {ChangeEvent} from 'react';

import {select} from '../sharedStyles';

type Props = {
  value: number;
  onChange: (value: number) => void;
};

export default class VCEExtensionStudy extends React.Component<Props> {
  render() {
    return (
      <li className="py-1 px-2">
        <span id="increment_subject_name" className="subject_name">
          <a
            href="http://www.vcaa.vic.edu.au/vce/studies/studiesextension.html"
            target="_blank">
            Extension study increment
          </a>{' '}
          (?)
          <p id="increment_help">
            Some Extension Studies (eg. University Maths) do not have a study
            score, but instead add a set amount (an "increment") to your
            aggregate. If you are doing one of these subjects, use the
            "Extension Study Increment" field to choose the increment. See{' '}
            <a
              href="http://www.vcaa.vic.edu.au/vce/studies/studiesextension.html"
              target="_blank">
              VCAA's site
            </a>{' '}
            for more information.
          </p>
        </span>
        <span className="subject_score">
          <select
            className={select}
            id="increment"
            name="increment"
            value={this.props.value}
            onChange={this._onChange}>
            <option>0</option>
            <option value="3">3.0 (average grade 50-60)</option>
            <option value="3.5">3.5 (average grade 60-70)</option>
            <option value="4">4.0 (average grade 70-80)</option>
            <option value="4.5">4.5 (average grade 80-90)</option>
            <option value="5">5.0 (average grade 90+)</option>
          </select>
        </span>
      </li>
    );
  }

  _onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    this.props.onChange(+evt.target.value);
  };
}
