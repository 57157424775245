export enum Views {
  RESULTS = 'RESULTS',
  SUBJECTS = 'SUBJECTS',
}

export enum State {
  VCE = 'vce',
  HSC = 'hsc',
  WACE = 'wace',
  QCE = 'qce',
}

export enum LetterGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}
